<template>
  <main>
      <h1 class="headline blue--text text-center mt-4 mb-3">Chat-aliasraportti</h1>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-autocomplete
              v-model="selected_agent"
              :search-input.sync="search_agent"
              :items="agents_list"
              item-value="agent_id"
              item-text="name"
              label="Etsi agentti"
              clearable
          >
            <template #item="{ item }">
              {{ item.name }}
            </template>
            <template #selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
              v-model="selected_alias"
              :search-input.sync="search_alias"
              :items="alias_list"
              :return-object="false"
              item-value="id"
              item-text="name"
              label="Etsi alias"
              clearable
          >
            <template #item="{ item }">
              {{ item.name }}
            </template>
            <template #selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-radio-group v-model="selected_type" >
            <v-radio value=1 label="Viihde"/>
            <v-radio value=2 label="Asiantuntija"/>
          </v-radio-group>
        </v-col>
      </v-row>
      <!--
      <v-row>
          <v-col>
              <v-select
                      v-model="selected_agent"
                      :items="agents_list"
                      :return-object="false"
                      item-value="agent_id"
                      item-name="name"
                      label="Valitse agentti"
                      clearable
              >
                  <template #item="{ item }">
                      {{ item.name }}
                  </template>
                  <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                      </span>
                  </template>
              </v-select>
          </v-col>
          <v-col>
              <v-select
                  v-model="selected_alias"
                  :items="alias_list"
                  :return-object="false"
                  item-value="id"
                  item-name="name"
                  label="Valitse alias"
                  clearable
                  :disabled="alias_list.length == 0"
              >
                  <template #item="{ item }">
                      {{ item.name }}
                  </template>
                  <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                      </span>
                  </template>
              </v-select>
          </v-col>
          <v-col>
              <v-radio-group v-model="selected_type" >
                  <v-radio value=1 label="Viihde"/>
                  <v-radio value=2 label="Asiantuntija"/>
              </v-radio-group>
          </v-col>
      </v-row>
      -->
      <v-row>
          <v-col cols="2">
              <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
          </v-col>
          <v-col cols="2">
              <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
          </v-col>
      </v-row>
      <v-card  class="mb-3">
          <v-card-text>
              <v-row>
                  <v-col v-for="btn in resourceSelectors" :key="btn.id" class="text-center">
                      <v-btn
                          small
                          :class="{'blue': selectedResourceSelector == btn.id}"
                          @click="selectedResourceSelector = btn.id"
                      >
                          {{ btn.name }}
                      </v-btn>
                  </v-col>
                  <v-col cols="1">
                      <v-btn
                          class="v-btn green float-right"
                          @click="getResource"
                          small
                      >
                          HAE
                      </v-btn>
                  </v-col>
              </v-row>
          </v-card-text>
      </v-card>
      <v-card v-if="!resourceLoading">
          <v-card-text>

              <!-- resource_day table -->
              <v-simple-table v-if="selectedResourceSelector == 'day'">
                  <thead>
                      <tr>
                          <td>Päivä</td>
                          <td>
                              Saapuneet yksityiset
                              <v-icon v-if="DayOrderedBy != 'received_private'" @click="DayOrderedBy = 'received_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                              <v-icon v-if="DayOrderedBy == 'received_private' && DayOrderedByStep == 1" @click="DayOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                              <v-icon v-if="DayOrderedBy == 'received_private' && DayOrderedByStep == 2" @click="DayOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                          </td>
                          <td>
                              Käsitelty
                              <v-icon v-if="DayOrderedBy != 'handled_private'" @click="DayOrderedBy = 'handled_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                              <v-icon v-if="DayOrderedBy == 'handled_private' && DayOrderedByStep == 1" @click="DayOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                              <v-icon v-if="DayOrderedBy == 'handled_private' && DayOrderedByStep == 2" @click="DayOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                          </td>
                          <td>
                              Käsittelemättä
                              <v-icon v-if="DayOrderedBy != 'not_handled_private'" @click="DayOrderedBy = 'not_handled_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                              <v-icon v-if="DayOrderedBy == 'not_handled_private' && DayOrderedByStep == 1" @click="DayOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                              <v-icon v-if="DayOrderedBy == 'not_handled_private' && DayOrderedByStep == 2" @click="DayOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                          </td>
                          <td>
                              Vastatut yksityiset
                              <v-icon v-if="DayOrderedBy != 'answered_private'" @click="DayOrderedBy = 'answered_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                              <v-icon v-if="DayOrderedBy == 'answered_private' && DayOrderedByStep == 1" @click="DayOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                              <v-icon v-if="DayOrderedBy == 'answered_private' && DayOrderedByStep == 2" @click="DayOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                          </td>
                          <td>
                              Julkiset
                              <v-icon v-if="DayOrderedBy != 'received_public'" @click="DayOrderedBy = 'received_public'">mdi-arrow-down-right</v-icon> <!-- default state -->
                              <v-icon v-if="DayOrderedBy == 'received_public' && DayOrderedByStep == 1" @click="DayOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                              <v-icon v-if="DayOrderedBy == 'received_public' && DayOrderedByStep == 2" @click="DayOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                          </td>
                          <td>
                              Laajoja
                              <v-icon v-if="DayOrderedBy != 'received_ordered_prophecy'" @click="DayOrderedBy = 'received_ordered_prophecy'">mdi-arrow-down-right</v-icon> <!-- default state -->
                              <v-icon v-if="DayOrderedBy == 'received_ordered_prophecy' && DayOrderedByStep == 1" @click="DayOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                              <v-icon v-if="DayOrderedBy == 'received_ordered_prophecy' && DayOrderedByStep == 2" @click="DayOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                          </td>
                          <td>
                              Vastatut laajat
                              <v-icon v-if="DayOrderedBy != 'answered_ordered_prophecy'" @click="DayOrderedBy = 'answered_ordered_prophecy'">mdi-arrow-down-right</v-icon> <!-- default state -->
                              <v-icon v-if="DayOrderedBy == 'answered_ordered_prophecy' && DayOrderedByStep == 1" @click="DayOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                              <v-icon v-if="DayOrderedBy == 'answered_ordered_prophecy' && DayOrderedByStep == 2" @click="DayOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                          </td>
                          <!--<td>Teaserit</td>-->
                      </tr>
                  </thead>
                  <tbody>
                  <tr v-for="r in resource_day" :key="'day'+r.sdate">
                      <td>{{ r.sdate }}</td>
                      <td>{{ r.received_private }}</td>
                      <td>{{ r.handled_private }}</td>
                      <td>{{ r.not_handled_private }}</td>
                      <td>{{ r.answered_private }}</td>
                      <td>{{ r.received_public }}</td>
                      <td>{{ r.received_ordered_prophecy }}</td>
                      <td>{{ r.aswered_ordered_prophecy }}</td>
                  </tr>
                  </tbody>
              </v-simple-table>

              <!-- resource_agent table -->
              <v-simple-table v-if="selectedResourceSelector == 'agent'">
                  <thead>
                  <tr>
                      <td>Agentti</td>
                    <td>
                      Saapuneet yksityiset
                      <v-icon v-if="AgentOrderedBy != 'received_private'" @click="AgentOrderedBy = 'received_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AgentOrderedBy == 'received_private' && AgentOrderedByStep == 1" @click="AgentOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AgentOrderedBy == 'received_private' && AgentOrderedByStep == 2" @click="AgentOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Käsitelty
                      <v-icon v-if="AgentOrderedBy != 'handled_private'" @click="AgentOrderedBy = 'handled_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AgentOrderedBy == 'handled_private' && AgentOrderedByStep == 1" @click="AgentOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AgentOrderedBy == 'handled_private' && AgentOrderedByStep == 2" @click="AgentOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Käsittelemättä
                      <v-icon v-if="AgentOrderedBy != 'not_handled_private'" @click="AgentOrderedBy = 'not_handled_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AgentOrderedBy == 'not_handled_private' && AgentOrderedByStep == 1" @click="AgentOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AgentOrderedBy == 'not_handled_private' && AgentOrderedByStep == 2" @click="AgentOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Vastatut yksityiset
                      <v-icon v-if="AgentOrderedBy != 'answered_private'" @click="AgentOrderedBy = 'answered_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AgentOrderedBy == 'answered_private' && AgentOrderedByStep == 1" @click="AgentOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AgentOrderedBy == 'answered_private' && AgentOrderedByStep == 2" @click="AgentOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Julkiset
                      <v-icon v-if="AgentOrderedBy != 'received_public'" @click="AgentOrderedBy = 'received_public'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AgentOrderedBy == 'received_public' && AgentOrderedByStep == 1" @click="AgentOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AgentOrderedBy == 'received_public' && AgentOrderedByStep == 2" @click="AgentOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Laajoja
                      <v-icon v-if="AgentOrderedBy != 'received_ordered_prophecy'" @click="AgentOrderedBy = 'received_ordered_prophecy'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AgentOrderedBy == 'received_ordered_prophecy' && AgentOrderedByStep == 1" @click="AgentOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AgentOrderedBy == 'received_ordered_prophecy' && AgentOrderedByStep == 2" @click="AgentOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Vastatut laajat
                      <v-icon v-if="AgentOrderedBy != 'answered_ordered_prophecy'" @click="AgentOrderedBy = 'answered_ordered_prophecy'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AgentOrderedBy == 'answered_ordered_prophecy' && AgentOrderedByStep == 1" @click="AgentOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AgentOrderedBy == 'answered_ordered_prophecy' && AgentOrderedByStep == 2" @click="AgentOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="r in resource_agent" :key="'agent'+r.agent.id">
                      <td>
                        {{ r.agent.lastname }} ({{ r.agent.agent_code }})
                        <v-icon @click="popAgent = r.agent;dialog=true">mdi-eye</v-icon>
                      </td>
                      <td>{{ r.received_private }}</td>
                      <td>{{ r.handled_private }}</td>
                      <td>{{ r.not_handled_private }}</td>
                      <td>{{ r.answered_private }}</td>
                      <td>{{ r.received_public }}</td>
                      <td>{{ r.received_ordered_prophecy }}</td>
                      <td>{{ r.aswered_ordered_prophecy }}</td>
                  </tr>
                  </tbody>
              </v-simple-table>

              <!-- resource_agent table -->
              <v-simple-table v-if="selectedResourceSelector == 'alias'">
                  <thead>
                  <tr>
                      <td>Alias</td>
                      <td>Agentti</td>
                    <td>
                      Saapuneet yksityiset
                      <v-icon v-if="AliasOrderedBy != 'received_private'" @click="AliasOrderedBy = 'received_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AliasOrderedBy == 'received_private' && AliasOrderedByStep == 1" @click="AliasOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AliasOrderedBy == 'received_private' && AliasOrderedByStep == 2" @click="AliasOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Käsitelty
                      <v-icon v-if="AliasOrderedBy != 'handled_private'" @click="AliasOrderedBy = 'handled_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AliasOrderedBy == 'handled_private' && AliasOrderedByStep == 1" @click="AliasOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AliasOrderedBy == 'handled_private' && AliasOrderedByStep == 2" @click="AliasOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Käsittelemättä
                      <v-icon v-if="AliasOrderedBy != 'not_handled_private'" @click="AliasOrderedBy = 'not_handled_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AliasOrderedBy == 'not_handled_private' && AliasOrderedByStep == 1" @click="AliasOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AliasOrderedBy == 'not_handled_private' && AliasOrderedByStep == 2" @click="AliasOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Vastatut yksityiset
                      <v-icon v-if="AliasOrderedBy != 'answered_private'" @click="AliasOrderedBy = 'answered_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AliasOrderedBy == 'answered_private' && AliasOrderedByStep == 1" @click="AliasOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AliasOrderedBy == 'answered_private' && AliasOrderedByStep == 2" @click="AliasOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Julkiset
                      <v-icon v-if="AliasOrderedBy != 'received_public'" @click="AliasOrderedBy = 'received_public'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AliasOrderedBy == 'received_public' && AliasOrderedByStep == 1" @click="AliasOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AliasOrderedBy == 'received_public' && AliasOrderedByStep == 2" @click="AliasOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Laajoja
                      <v-icon v-if="AliasOrderedBy != 'received_ordered_prophecy'" @click="AliasOrderedBy = 'received_ordered_prophecy'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AliasOrderedBy == 'received_ordered_prophecy' && AliasOrderedByStep == 1" @click="AliasOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AliasOrderedBy == 'received_ordered_prophecy' && AliasOrderedByStep == 2" @click="AliasOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                    <td>
                      Vastatut laajat
                      <v-icon v-if="AliasOrderedBy != 'answered_ordered_prophecy'" @click="AliasOrderedBy = 'answered_ordered_prophecy'">mdi-arrow-down-right</v-icon> <!-- default state -->
                      <v-icon v-if="AliasOrderedBy == 'answered_ordered_prophecy' && AliasOrderedByStep == 1" @click="AliasOrderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
                      <v-icon v-if="AliasOrderedBy == 'answered_ordered_prophecy' && AliasOrderedByStep == 2" @click="AliasOrderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="r in resource_alias" :key="'agent'+r.alias.id">
                      <td>{{ r.alias.name }}</td>
                      <td>{{ r.agent.lastname }} ({{ r.agent.agent_code }})</td>
                      <td>{{ r.received_private }}</td>
                      <td>{{ r.handled_private }}</td>
                      <td>{{ r.not_handled_private }}</td>
                      <td>{{ r.answered_private }}</td>
                      <td>{{ r.received_public }}</td>
                      <td>{{ r.received_ordered_prophecy }}</td>
                      <td>{{ r.aswered_ordered_prophecy }}</td>
                  </tr>
                  </tbody>
              </v-simple-table>

          </v-card-text>
      </v-card>
      <v-card v-else-if="resourceLoading">
          <v-card-text>
              Ladataan...
          </v-card-text>
      </v-card>

    <!-- events dialog -->
    <v-dialog
        v-model="dialog"
        max-width="1200"
    >
      <v-card v-if="popAgent">
        <v-card-text>
          <h2>
            Agentti: {{ popAgent.firstname }} {{ popAgent.lastname }} ({{ popAgent.agent_code }})
          </h2>


          <!-- resource_agent table -->
          <v-simple-table v-if="!resourceLoading">
            <thead>
            <tr>
              <td>Alias</td>
              <td>
                Saapuneet yksityiset
                <v-icon v-if="AliasOrderedByPop != 'received_private'" @click="AliasOrderedByPop = 'received_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                <v-icon v-if="AliasOrderedByPop == 'received_private' && AliasOrderedByStepPop == 1" @click="AliasOrderedByStepPop++">mdi-arrow-down</v-icon> <!-- desc order state -->
                <v-icon v-if="AliasOrderedByPop == 'received_private' && AliasOrderedByStepPop == 2" @click="AliasOrderedByStepPop++">mdi-arrow-up</v-icon> <!-- asc order state -->
              </td>
              <td>
                Käsitelty
                <v-icon v-if="AliasOrderedByPop != 'handled_private'" @click="AliasOrderedByPop = 'handled_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                <v-icon v-if="AliasOrderedByPop == 'handled_private' && AliasOrderedByStepPop == 1" @click="AliasOrderedByStepPop++">mdi-arrow-down</v-icon> <!-- desc order state -->
                <v-icon v-if="AliasOrderedByPop == 'handled_private' && AliasOrderedByStepPop == 2" @click="AliasOrderedByStepPop++">mdi-arrow-up</v-icon> <!-- asc order state -->
              </td>
              <td>
                Käsittelemättä
                <v-icon v-if="AliasOrderedByPop != 'not_handled_private'" @click="AliasOrderedByPop = 'not_handled_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                <v-icon v-if="AliasOrderedByPop == 'not_handled_private' && AliasOrderedByStepPop == 1" @click="AliasOrderedByStepPop++">mdi-arrow-down</v-icon> <!-- desc order state -->
                <v-icon v-if="AliasOrderedByPop == 'not_handled_private' && AliasOrderedByStepPop == 2" @click="AliasOrderedByStepPop++">mdi-arrow-up</v-icon> <!-- asc order state -->
              </td>
              <td>
                Vastatut yksityiset
                <v-icon v-if="AliasOrderedByPop != 'answered_private'" @click="AliasOrderedByPop = 'answered_private'">mdi-arrow-down-right</v-icon> <!-- default state -->
                <v-icon v-if="AliasOrderedByPop == 'answered_private' && AliasOrderedByStepPop == 1" @click="AliasOrderedByStepPop++">mdi-arrow-down</v-icon> <!-- desc order state -->
                <v-icon v-if="AliasOrderedByPop == 'answered_private' && AliasOrderedByStepPop == 2" @click="AliasOrderedByStepPop++">mdi-arrow-up</v-icon> <!-- asc order state -->
              </td>
              <td>
                Julkiset
                <v-icon v-if="AliasOrderedByPop != 'received_public'" @click="AliasOrderedByPop = 'received_public'">mdi-arrow-down-right</v-icon> <!-- default state -->
                <v-icon v-if="AliasOrderedByPop == 'received_public' && AliasOrderedByStepPop == 1" @click="AliasOrderedByStepPop++">mdi-arrow-down</v-icon> <!-- desc order state -->
                <v-icon v-if="AliasOrderedByPop == 'received_public' && AliasOrderedByStepPop == 2" @click="AliasOrderedByStepPop++">mdi-arrow-up</v-icon> <!-- asc order state -->
              </td>
              <td>
                Laajoja
                <v-icon v-if="AliasOrderedByPop != 'received_ordered_prophecy'" @click="AliasOrderedByPop = 'received_ordered_prophecy'">mdi-arrow-down-right</v-icon> <!-- default state -->
                <v-icon v-if="AliasOrderedByPop == 'received_ordered_prophecy' && AliasOrderedByStepPop == 1" @click="AliasOrderedByStepPop++">mdi-arrow-down</v-icon> <!-- desc order state -->
                <v-icon v-if="AliasOrderedByPop == 'received_ordered_prophecy' && AliasOrderedByStepPop == 2" @click="AliasOrderedByStepPop++">mdi-arrow-up</v-icon> <!-- asc order state -->
              </td>
              <td>
                Vastatut laajat
                <v-icon v-if="AliasOrderedByPop != 'answered_ordered_prophecy'" @click="AliasOrderedByPop = 'answered_ordered_prophecy'">mdi-arrow-down-right</v-icon> <!-- default state -->
                <v-icon v-if="AliasOrderedByPop == 'answered_ordered_prophecy' && AliasOrderedByStepPop == 1" @click="AliasOrderedByStepPop++">mdi-arrow-down</v-icon> <!-- desc order state -->
                <v-icon v-if="AliasOrderedByPop == 'answered_ordered_prophecy' && AliasOrderedByStepPop == 2" @click="AliasOrderedByStepPop++">mdi-arrow-up</v-icon> <!-- asc order state -->
              </td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="r in popAgentResouces" :key="'popAgentResouces'+r.alias.id">
              <td>{{ r.alias.name }}</td>
              <td>{{ r.received_private }}</td>
              <td>{{ r.handled_private }}</td>
              <td>{{ r.not_handled_private }}</td>
              <td>{{ r.answered_private }}</td>
              <td>{{ r.received_public }}</td>
              <td>{{ r.received_ordered_prophecy }}</td>
              <td>{{ r.aswered_ordered_prophecy }}</td>
            </tr>
            </tbody>
          </v-simple-table>
          <template v-else>
            Ladataan...
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

  </main>
</template>

<script>
export default {
    name: "ChatAliasReport",
    data: function () {
        return {
            startDate: null,
            endDate: null,
            resourceLoading: null,
            resource_agent: [],
            resource_agent_original: [],
            resource_alias: [],
            resource_alias_original: [],
            resource_day: [],
            resource_original_day: [],
            selected_agent: null,
            selected_alias: null,
            search_agent: "",
            search_alias: "",
            loading: false,
            agents_list: [],
            alias_list: [],
            selected_type: "1",
            resourceSelectors: [
                {id: 'day', name: 'Päivät'},
                {id: 'agent', name: 'Agentit'},
                {id: 'alias', name: 'Aliakset'},
            ],
            selectedResourceSelector: 'day',
            DayOrderedBy: null,
            DayOrderedByStep: 0,
          AgentOrderedBy: null,
          AgentOrderedByStep: 0,
          AliasOrderedBy: null,
          AliasOrderedByStep: 0,
          dialog: false,
          popAgent: null,
          popAgentResouces: [],
          popAgentResoucesOriginal: [],
          AliasOrderedByPop: null,
          AliasOrderedByStepPop: 0,
          search_timeout: null,
        }
    },
    watch:{
        /*
        search_agent(){
          if(this.search_agent && !this.loading){
            this.loading = true
            this.axios.get('agents_list', {
              params: {
                skip: 0,
                take: 10,
                order: 'firstname',
                order_direction: 'DESC',
                keyword: this.search_agent
              }
            }).then((response)=>{
                if(response.data.length > 0)this.agents_list = response.data
              this.loading = false
            })
          }


        },

         */
      selected_type(){
        this.alias_list = [] //reset alias list when changed
      },

      search_alias(){

        clearTimeout(this.search_timeout)

        if(this.search_alias)
          this.search_timeout = setTimeout(() => {

              this.loading = true
              this.axios.get('chat_aliases', {
                params: {
                  skip: 0,
                  take: 500,
                  order: 'name',
                  order_direction: 'ASC',
                  name: this.search_alias,
                  type: this.selected_type
                }
              }).then((response)=>{
                this.alias_list = response.data
                this.loading = false
              })
          }, 500)
      },

      selected_agent(){
            if(this.selected_agent){
                this.axios.get('chat_aliases', {params:
                        {
                            agent_id: this.selected_agent,
                            skip: 0,
                            take: 500,
                            active: true,
                        }
                }).then((r) => {
                    this.alias_list = r.data
                }).catch(
                    this.alias_list = []
                )
            }
        },
        selectedResourceSelector(){
            if(this.selectedResourceSelector == 'day' && this.resource_day.length == 0) this.getResource()
            if(this.selectedResourceSelector == 'agent' && this.resource_agent.length == 0) this.getResource()
            if(this.selectedResourceSelector == 'alias' && this.resource_alias.length == 0) this.getResource()
        },
        DayOrderedBy(){

            if(this.resource_original_day.length == 0) this.resource_original_day = [...this.resource_day];
            if(this.DayOrderedBy) {
                this.DayOrderedByStep = 1
                this.orderResourcesDay()
            }
        },
        DayOrderedByStep(){

            //going over step 2 will reset the order
            if(this.DayOrderedByStep > 2){
                this.DayOrderedByStep = 0
                this.DayOrderedBy = null,
                this.resource_day = [...this.resource_original_day];
            }else
                this.orderResourcesDay()
        },
        AgentOrderedBy(){

          if(this.resource_agent_original.length == 0) this.resource_agent_original = [...this.resource_agent];
          if(this.AgentOrderedBy) {
            this.AgentOrderedByStep = 1
            this.orderResourcesAgent()
          }
        },
        AgentOrderedByStep(){

          //going over step 2 will reset the order
          if(this.AgentOrderedByStep > 2){
            this.AgentOrderedByStep = 0
            this.AgentOrderedBy = null,
                this.resource_agent = [...this.resource_agent_original];
          }else
            this.orderResourcesAgent()
        },
        AliasOrderedBy(){

          if(this.resource_alias_original.length == 0) this.resource_alias_original = [...this.resource_alias];
          if(this.AliasOrderedBy) {
            this.AliasOrderedByStep = 1
            this.orderResourcesAlias()
          }
        },
        AliasOrderedByStep(){

          //going over step 2 will reset the order
          if(this.AliasOrderedByStep > 2){
            this.AliasOrderedByStep = 0
            this.AliasOrderedBy = null,
                this.resource_alias = [...this.resource_alias_original];
          }else
            this.orderResourcesAlias()
        },
        popAgent(){
            if(this.popAgent){
              this.resourceLoading = true
              this.axios.post('reports/chat_alias', {
                from: this.startDate,
                to: this.endDate,
                type: Number(this.selected_type),
                agent_id: this.popAgent.id,
                alias_id: this.selected_alias,
                mode: 'agent',
                agent_alias: 1,
              })
                  .then((resources) => {

                    this.popAgentResouces = resources.data['agent_data'][0]['aliases']
                    this.resourceLoading = false
                    this.AliasOrderedByStepPop = 0
                    this.AliasOrderedByPop = null
                  })
            }
        },

        AliasOrderedByPop(){

          if(this.popAgentResoucesOriginal.length == 0) this.popAgentResoucesOriginal = [...this.popAgentResouces];
          if(this.AliasOrderedByPop) {
            this.AliasOrderedByStepPop = 1
            this.orderResourcesAliasPop()
          }
        },
        AliasOrderedByStepPop(){

          //going over step 2 will reset the order
          if(this.AliasOrderedByStepPop > 2){
            this.AliasOrderedByStepPop = 0
            this.AliasOrderedByPop = null,
                this.popAgentResouces = [...this.popAgentResoucesOriginal];
          }else
            this.orderResourcesAliasPop()
        },
    },
    methods: {

        orderResourcesDay(){

            if(this.DayOrderedByStep == 1){
                this.resource_day.sort(function(a, b) {
                    return b[this.DayOrderedBy] - a[this.DayOrderedBy];
                }.bind(this));
            }

            if(this.DayOrderedByStep == 2){
                this.resource_day.sort(function(a, b) {
                        return a[this.DayOrderedBy] - b[this.DayOrderedBy];
                }.bind(this));
            }

        },

        orderResourcesAgent(){

          if(this.AgentOrderedByStep == 1){
            this.resource_agent.sort(function(a, b) {
              return b[this.AgentOrderedBy] - a[this.AgentOrderedBy];
            }.bind(this));
          }

          if(this.AgentOrderedByStep == 2){
            this.resource_agent.sort(function(a, b) {
              return a[this.AgentOrderedBy] - b[this.AgentOrderedBy];
            }.bind(this));
          }

        },

        orderResourcesAlias(){

          if(this.AliasOrderedByStep == 1){
            this.resource_alias.sort(function(a, b) {
              return b[this.AliasOrderedBy] - a[this.AliasOrderedBy];
            }.bind(this));
          }

          if(this.AgentOrderedByStep == 2){
            this.resource_alias.sort(function(a, b) {
              return a[this.AliasOrderedBy] - b[this.AliasOrderedBy];
            }.bind(this));
          }

        },

        orderResourcesAliasPop(){

          if(this.AliasOrderedByStepPop == 1){
            this.popAgentResouces.sort(function(a, b) {
              return b[this.AliasOrderedByPop] - a[this.AliasOrderedByPop];
            }.bind(this));
          }

          if(this.AliasOrderedByStepPop == 2){
            this.popAgentResouces.sort(function(a, b) {
              return a[this.AliasOrderedByPop] - b[this.AliasOrderedByPop];
            }.bind(this));
          }

        },

        async getResource(){
            if(this.resourceLoading) return
            this.resourceLoading = true
            this.resource = null
            await this.axios.post('reports/chat_alias', {
                from: this.startDate,
                to: this.endDate,
                type: Number(this.selected_type),
                agent_id: this.selected_agent,
                alias_id: this.selected_alias,
                mode: this.selectedResourceSelector,
                agent_alias: 0,
            })
                .then((resources) => {
                    //console.table(resources.data['agent_data'][0])
                    //console.table(resources.data['alias_data'][0])
                    //console.table(resources.data['daily_data'][0])
                    //this.resource = resources.data
                    if(resources.data['agent_data']) this.resource_agent = resources.data['agent_data']
                    if(resources.data['alias_data']) this.resource_alias = resources.data['alias_data']
                    if(resources.data['daily_data']) this.resource_day = resources.data['daily_data']

                    //if(resources.data['agent_data']) console.table(resources.data['agent_data'][0])
                })
                .catch ((error) => {console.error(error);});
            this.resourceLoading = false
        },
    },
    mounted() {
        this.startDate = this.$day().subtract(7, 'day').startOf('day').format('DD.MM.YYYY')
        this.endDate = this.$day().format("DD.MM.YY")
        //this.getResource()
        this.axios.get('reports/agent_list', {
            params: {
                feature: ['2','4'],
            }
        }).then((r) => {
            this.agents_list = r.data
        }) //get agents_list
    }
}
</script>

<style scoped>

</style>